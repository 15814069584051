import React from "react";

const About = () => {
  return (
    <div id="#business">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12 items-center lg:py-28 md:pt-12 md:pb-28 pt-10 pb-20">
          <div className="lg:col-span-12 flex flex-row items-center gap-8">
            <div className="flex flex-col xl:pr-8">
              <h2 className="md:text-display-lg text-display-md font-semibold md:pb-6 pb-4">
                Why should you hire us?
              </h2>
              <h3 className="md:text-display-md text-display-md font-semibold md:pb-6 pb-4">Software Development</h3>
              <p className="text-body-md font-normal text-neutral-700">
                Setting up an internal Soft- or Hardware team is costly and time-consuming. Especially in the early phases of a company, this part can easily be outsourced.
                It is difficult to hire experienced engineers and to assess the experience if you do not already have it in-house. We offer the expertise so you can focus on building your company.
              </p>
              <h3 className="md:text-display-md text-display-md font-semibold md:pb-6 pb-4">Data Science</h3>
              <p className="text-body-md font-normal text-neutral-700">
                In science-based companies, more often than not, the scientists themselves dabble in the big world of Data Science, Artificial Intelligence and Machine Learning.
                It takes a lot of time get fully acquainted and be able to provide a platform, time better spent letting scientists be scientists and focus on the core aspect of your company.
                Working with us will minimize the impact on the overall growth of your business due to short iteration loops of your project.
              </p>
              <h3 className="md:text-display-md text-display-md font-semibold md:pb-6 pb-4">Prototyping</h3>
              <p className="text-body-md font-normal text-neutral-700">
                An extra pair of eyes for soft- or Hardware prototyping or establishing a scalable experimental/testing setup is certainly handy. We aim to be a sparring partner for your R&D department.
                If needed, we can also purchase the hardware, assemble the prototype/setup and make sure the installation is fully functional on-site.
              </p>
              <h3 className="md:text-display-md text-display-md font-semibold md:pb-6 pb-4">IoT and Smart Solutions</h3>
              <p className="text-body-md font-normal text-neutral-700">
                We can help your company with the ever-growing need of predictive solutions for heavy equipment or machinery.
                Monitoring your work environment with various sensors can enable you to capture more insights and change your processes.
                This can greatly reduce cost and decrease the strain on your employees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
