import React from "react";

import Development from "../images/service-icons/ui.svg";
import IoT from "../images/service-icons/motion.svg";
import Prototyping from "../images/service-icons/brand.svg";
import Button from "./button";

const Services = () => {
  return (
    <div id="#services">
      <div className="md:container md:mx-auto">
        <div className="lg:py-30 md:py-16 pt-5 pb-12">
          <div className="flex flex-col relative bg-secondary-100 lg:py-0 py-10">
            <div className="absolute lg:block hidden h-16 bg-white bottom-0 left-0 right-0"></div>
            <div className="absolute lg:block hidden h-16 bg-white top-0 left-0 right-0"></div>
            <div className="grid lg:grid-cols-12 grid-cols-1 xl:gap-8 items-center  relative">
              <div className="lg:col-span-5 flex flex-col items-start xl:px-18 lg:px-10 md:px-8 px-5 lg:py-32 md:pb-4 pb-10">
                <h2 className="lg:text-display-lg text-display-md font-semibold pb-4">
                  What we can provide
                </h2>
                <p className="text-body-md font-normal text-neutral-600 pb-8">
                  We provide services in the field of Electronics and IT engineering.
                </p>
              </div>
              <div className="lg:col-span-7 flex md:flex-row flex-col lg:gap-8 gap-6 items-center xl:pr-16 lg:pr-10 lg:pl-0 md:px-8 px-5 relative">
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Development} alt="Development" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      Software Development and Data Science
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Don't want to hire an internal Software team? Looking to minimize the overhead? Right up our alley!
                      We have experience working with custom AI, ML and Software tooling tailored to your business case.
                      We have extensive knowledge of the Microsoft Azure platform.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <div className="bg-white p-8 shadow-xl">
                    <img src={Prototyping} alt="Prototyping" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      Experiment and product prototyping
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      Need a prototype of a certain product or you want to optimize your current testing/experiment set-up? 
                      We provide scalable solutions using high-quality products.
                    </p>
                  </div>
                  <div className="bg-white p-8 shadow-xl">
                    <img src={IoT} alt="IoT" className="pb-8" />
                    <p className="text-display-xs font-semibold pb-4">
                      IoT and Smart Solutions
                    </p>
                    <p className="text-body-md font-normal text-neutral-600">
                      We provide development of Edge Iot devices and smart solutions for energy monitoring, maintenance and general device solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
